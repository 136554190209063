



















































































































































































































































































import { BookingDTO } from '@/api/flightbooking'
import { mapState, mapMutations } from 'vuex'
export default {
  data: () => ({
    loading: false,
    modelState: {},
    minimumDate: new Date().toISOString().slice(0, 10),
    dialog: false,
    startDate: null,
    startDateMenu: false,
    endDate: null,
    endDateMenu: false,
    startTime: null,
    startTimeMenu: false,
    endTime: null,
    endTimeMenu: false,
    editedBooking: {} as BookingDTO,
  }),
  watch: {
    startDate: function () {
      this.updateTimes()
    },
    startTime: function () {
      this.updateTimes()
    },
    endDate: function () {
      this.updateTimes()
    },
    endTime: function () {
      this.updateTimes()
    },
  },
  computed: {
    ...mapState('users', ['users', 'currentUser']),
    ...mapState('aircraft', ['aircraft']),
    title() {
      return !this.editedBooking.id ? 'New Booking' : 'Edit booking'
    },
    minimumTime() {
      const d = new Date()
      if (this.startDate == d.toISOString().slice(0, 10)) {
        return d.toISOString().slice(11, 16)
      } else {
        return '00:00'
      }
    },
  },
  created() {
    this.$store.dispatch('aircraft/getAircraft')
    this.$store.dispatch('users/getUsers')
  },
  methods: {
    showDialog(booking) {
      this.loading = true
      this.editedBooking = Object.assign({}, booking)
      const s = new Date(
        this.editedBooking.start.getTime() -
          this.editedBooking.start.getTimezoneOffset() * 60000
      ).toISOString()
      const e = new Date(
        this.editedBooking.end.getTime() -
          this.editedBooking.end.getTimezoneOffset() * 60000
      ).toISOString()
      this.startDate = s.slice(0, 10)
      this.startTime = s.slice(11, 16)
      this.endDate = e.slice(0, 10)
      this.endTime = e.slice(11, 16)
      this.loading = false
      this.dialog = true
    },
    allowedStep: (m) => m % 15 === 0,
    updateTimes() {
      if (this.loading) return
      if (this.startDate && this.startTime) {
        this.editedBooking.start = this.startDate + 'T' + this.startTime + 'Z'
      }
      if (this.endDate && this.endTime) {
        this.editedBooking.end = this.endDate + 'T' + this.endTime + 'Z'
      }
    },
    UpsertBooking() {
      this.$store
        .dispatch('bookings/saveBooking', this.editedBooking)
        .then((ok) => {
          this.dialog = false
          this.modelState = {}
        })
        .catch((error) => {
          if (error.status == 400) {
            this.modelState = JSON.parse(error.response)
          } else {
            console.log(error)
          }
        })
    },
  },
}
