<template>
  <v-container>
    <bookings-calendar />
    <booking-dialog ref="newBookingDialog" />
  </v-container>
</template>

<script>
import BookingsCalendar from '@/components/Booking/BookingsCalendar.vue'
import BookingDialog from '@/components/Booking/BookingDialog'
export default {
  name: 'Bookings',
  components: {
    BookingsCalendar,
    BookingDialog
  }
}
</script>

<style></style>
